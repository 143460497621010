import WebComponent from '../../WebComponent.js';
import {html} from '../../_snowpack/pkg/lit-html.js';

export default class BasicPagination extends WebComponent {
    static get EVENT_KEYS() {
        return {
            PAGE_CHANGED_EVENT: 'paging-page-changed',
        }
    }

    static get observedAttributes() {
        return ['page', 'pages', 'page-size', 'count'];
    }

    get cssFile() {
        return './views/Paging/style.css';
    }

    static get properties() {
        return {
            count: {
                type: Number,
                reflect: true,
                attribute: true
            },
            page: {
                type: Number,
                reflect: true,
                attribute: true
            },
            pages: {
                type: Number,
                reflect: true,
                attribute: true
            }
        };
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
            case 'page':
                this.page = parseInt(newValue);
                break;
            case 'count':
                this.count = parseInt(newValue);
                break;
            case 'pages':
                this.pages = parseInt(newValue);
                break;
        }
        this.render();
    }

    onPageChange(newValue, oldValue) {
        this.dispatchEvent(new CustomEvent(BasicPagination.EVENT_KEYS.PAGE_CHANGED_EVENT, {
            detail: {newPage: newValue, oldPage: oldValue},
            bubbles: true,
            composed: true
        }));
    }

    goFirst() {
        this.onPageChange(1, this.page);
    }

    goBack() {
        let backPage = Math.max(this.page - 1, 1);
        this.onPageChange(backPage, this.page);
    }

    goFwd() {
        let fwdPage = Math.min(this.page + 1, this.pages);
        this.onPageChange(fwdPage, this.page);
    }

    goLast() {
        this.onPageChange(this.pages, this.page);
    }

    connectedCallback() {
        this.render();
    }

    disconnectedCallback() {
    }

    getTemplate() {
        return html`
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/menu.css"
                  integrity="sha256-QXVhP5B9h80o2da7dZ1P0o/iU6y/q26xiudmCtphp5k="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
                  integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/button.css"
                  integrity="sha256-wpzJilWuuuCiST+1kQ6j3vLpVZT7CVZ2WsVtcdA5cKE="
                  crossorigin="anonymous">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/label.min.css">
            <div style="text-align: center; padding-bottom: 50px;">
                <button class="ui button mini"
                        style="padding-right:8px !important;"
                        ?disabled="${this.page === 1}"
                        @click="${() => this.goFirst()}"
                ><i style="font-size: 1em;" class="angle double left icon"></i></button>

                <button class="ui button mini"
                        style="padding-right:8px !important;"
                        ?disabled="${this.pages === 1 || this.page === 1}"
                        @click="${() => this.goBack()}"
                ><i style="font-size: 1em;" class="angle left icon"></i>
                </button>
                <div class="ui large label"
                     style="padding-top:8px !important;padding-left:5px !important;padding-right:5px !important;padding-bottom:9px !important;font-size:0.80rem;">
                    ${this.page}/${this.pages}
                </div>
                <button class="ui button mini"
                        style="padding-left:8px !important;"
                        ?disabled="${this.pages === 1 || (this.pages > 1 && this.page === this.pages)}"
                        @click="${() => this.goFwd()}"
                ><i style="font-size: 1em;" class="angle right icon"></i>
                </button>
                <button class="ui button mini"
                        style="padding-left:8px !important;"
                        ?disabled="${this.pages === 1 || (this.pages > 1 && this.page === this.pages)}"
                        @click="${() => this.goLast()}"
                ><i style="font-size: 1em;" class="angle double right icon"></i>
                </button>
            </div>
        `
    }
}

customElements.define('basic-pagination', BasicPagination);