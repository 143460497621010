import LoggingService from './LoggingService.js';

export default class BaseService {

    constructor(relativeBase) {
        this.queryUrl = '';
        this.commandUrl = '';
        this.relativeBase = relativeBase;
        this.logging = new LoggingService();
    }

    retrieveConfiguration() {
        return fetch('./config/config.json', {cache: 'reload'})
            .then(res => res.json());
    }

    retrieveQueryUrl() {
        return this.retrieveConfiguration()
            .then(config => {
                this.queryUrl = `${config.queryUrl}/customer-access-backend/resources/${this.relativeBase}`;
            })
            .catch(error => {
                this.logging.log(error);
            });
    }

    retrieveCommandUrl() {
        return this.retrieveConfiguration()
            .then(config => {
                this.commandUrl = `${config.commandUrl}/co-recall-admin-backend/resources/${this.relativeBase}`;
            })
            .catch(error => {
                this.logging.log(error);
            });
    }

    async retrieveAll(queryParam) {
        await this.retrieveQueryUrl();
        let url = this.queryUrl
        url = this.getParameterizedUrl(url, queryParam);

        return fetch(url, {cache: 'reload'})
            .then(result => result.json())
            .catch(error => {
                this.logging.log(error);
            });
    }

    getParameterizedUrl(url, queryParam) {
        if (Array.isArray(queryParam) && queryParam.length > 0) {
            let params = queryParam.map(qParam => {
                return `${qParam.key}=${qParam.value}`
            }).join('&')
            url += `?${params}`
        } else if (queryParam !== null && typeof queryParam == 'object') {
            if (this.isNullOrUndefined(queryParam.key) || this.isNullOrUndefined(queryParam.value))
                return url;
            url += `?${queryParam.key}=${queryParam.value}`
        }
        return url;
    }

    async retrieve(id) {
        await this.retrieveQueryUrl();
        return fetch(`${this.queryUrl}/${id}`, {cache: 'reload'})
            .then(result => result.json())
            .catch(error => {
                this.logging.log(error);
            });
    }

    async post(object) {
        await this.retrieveCommandUrl();
        return fetch(this.commandUrl, {
            method: 'POST',
            cache: 'reload',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(object)
        })
    }

    async put(object) {
        await this.retrieveCommandUrl();
        return fetch(this.commandUrl, {
            method: 'PUT',
            cache: 'reload',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(object)
        })
    }

    isNullOrUndefined(param) {
        if ((param === null) || (typeof param == "undefined"))
            return true;
        return false;
    }
}