import {html, render} from './_snowpack/pkg/lit-html.js'
import I18n from './i18n.js';
import LoggingService from './LoggingService.js';

export default class WebComponent extends HTMLElement {

    constructor() {
        super();
        this.logging = new LoggingService();
        this.root = this.attachShadow({mode: 'open'});
        this.i18n = new I18n();

        if (this.translationFile) {
            this.i18n.loadConfiguration(this.translationFile)
                .then(() => {
                    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, _ => {
                        this.reload()
                    });
                    this.reload();
                })
                .catch(error => {
                    this.logging.log(error);
                });
        }
    }

    reload() {
        this.render()
    }

    render() {
        if (this.cssFile) {
            fetch(this.cssFile)
                .then(res => res.text())
                .then(css => {
                    render(html`
                        <style>
                            ${css}
                        </style>
                        ${this.getTemplate()}
                    `, this.root);
                })
                .catch(error => {
                    this.logging.log(error);
                });
        } else {
            render(this.getTemplate(), this.root);
        }
    }
}
