import { html } from '../../_snowpack/pkg/lit-html.js';
import Shared from '../Shared/Shared.js';
export default class Impressum extends Shared {

    get translationFile() {
        return './views/Impressum/i18n.json'
    }

    get cssFile() {
        return './views/Impressum/style.css';
    }

    getTemplate() {
        return html`
          <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
          integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
          crossorigin="anonymous">
          <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/button.css"
          integrity="sha256-wpzJilWuuuCiST+1kQ6j3vLpVZT7CVZ2WsVtcdA5cKE="
          crossorigin="anonymous">

<div class="impressum">
    <div class="image">
                    <img
                            alt="Swiss"
                            src="images/logoswiss.jpg"
                            >
    </div>
      <p>${this.i18n.translate('impressum.title')}</p>
        ${this.i18n.translate('impressum.address.co')}<br />
        ${this.i18n.translate('impressum.address.address')}<br />
        ${this.i18n.translate('impressum.address.place')}<br />
        <a href="tel:+41584622021">+41 58 462 20 21</a><br />
        <a href="mailto:${this.i18n.translate('impressum.address.email')}">${this.i18n.translate('impressum.address.email')}<br />
        <a href="https://${this.i18n.translate('impressum.address.url')}" target="_blank">${this.i18n.translate('impressum.address.url')}</a><br />
        <br />
        ${this.i18n.translate('impressum.addition.title')}<br />
        ${this.i18n.translate('impressum.addition.write.us')}<br />
        <a href="mailto:${this.i18n.translate('impressum.addition.email')}">${this.i18n.translate('impressum.addition.email')}</a><br />

        <button
        @click="${_ => window.location.hash = 'Recalls'}"
        class="ui button"><i class="home icon"></i> ${this.i18n.translate('impressum.back')}</button>
        <p>&nbsp</p>
</div>
        `
    }
}

customElements.define('customer-access-impressum', Impressum)

