import WebComponent from '../../WebComponent.js';
import { html } from '../../_snowpack/pkg/lit-html.js';
import QuickNavigation from '../QuickNavigation/QuickNavigation.js';

export default class Language extends WebComponent {

  connectedCallback() {
    this.wirtschaftszweigFilterChangedListener = e => {
      sessionStorage.setItem('wirtschaftszweigId', e.detail);
      window.location.hash = 'Recalls';
    };
    document.addEventListener(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.wirtschaftszweigFilterChangedListener);
  }

  disconnectedCallback() {
    document.removeEventListener(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.wirtschaftszweigFilterChangedListener);
  }

  get translationFile() {
    return './views/Language/i18n.json'
  }

  get cssFile() {
    return './views/Language/style.css';
  }

  getTemplate() {
    return html`
          <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
          integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
          crossorigin="anonymous">
          <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/button.css"
          integrity="sha256-wpzJilWuuuCiST+1kQ6j3vLpVZT7CVZ2WsVtcdA5cKE="
          crossorigin="anonymous">
          <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/list.css"
          integrity="sha256-A2c05RTW3wySXORRcMvhGFVzEF4lKGOxXawOjaUlNoE="
          crossorigin="anonymous">

<div class="language">
    <h1>${this.i18n.translate('language.title')}</h1>
    <div class="ui relaxed divided list">
  <div
  class="item"
  @click="${_ => {
        setLanguage('de')
        this.render()
      }}"
  >
    <i class="circle ${(getLanguage() === 'de' ? 'check' : 'outline')} icon"></i>
    <div class="content">
      <a class="header">${this.i18n.translate('language.german')}</a>
    </div>
  </div>
  <div class="item"
  @click="${_ => {
        setLanguage('fr')
        this.render()
      }}"
  >
    <i class="circle ${(getLanguage() === 'fr' ? 'check' : 'outline')} icon"></i>
    <div class="content">
      <a class="header">${this.i18n.translate('language.french')}</a>
    </div>
  </div>
  <div class="item"
  @click="${_ => {
        setLanguage('it')
        this.render()
      }}"
  >
    <i class="circle ${(getLanguage() === 'it' ? 'check' : 'outline')} icon"></i>
    <div class="content">
      <a class="header">${this.i18n.translate('language.italian')}</a>
    </div>
  </div>
</div>
        <button
        @click="${(this.goBack)}"
        class="ui button">${this.i18n.translate('language.back')}</button>
</div>
        `
  }

  goBack() {
    window.history.back();
  }
}

customElements.define('customer-access-language', Language)

