import WebComponent from '../../WebComponent.js';
import I18n from '../../i18n.js';
import RecallService from '../Recalls/RecallService.js';
import RecallDetail from './RecallDetail.js';
import {html} from '../../_snowpack/pkg/lit-html.js';

export default class Detail extends WebComponent {

    constructor(id) {
        super();
        this.id = id;

        this.shareEvent = 'detail-share-event'
        this.isMobileOrTablet = screen.width < 950 ? true : false;
    }

    connectedCallback() {
        this.detail = null;
        this.handleOnShare = ev => {
            navigator.share({
                    title: this.detail.header,
                    text: this.detail.description,
                    url: location.href,
                },
                {
                    copy: true,
                    email: true,
                    print: false,
                    sms: true,
                    messenger: false,
                    facebook: true,
                    whatsapp: true,
                    twitter: true,
                    linkedin: false,
                    telegram: false,
                    skype: false,
                    language: getLanguage() // specify the default language
                }
            ).then(_ => {
                this.addMatomoTrackEvent('General icon', this.detail.header);
            })
                .catch(error => console.log('Share failure', error));
        }
        this.changeLanguageListener = () => {
            this.render();
        };
        document.addEventListener(this.shareEvent, this.handleOnShare)
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.changeLanguageListener);
        new RecallService()
            .retrieve(this.id)
            .then(detail => {
                this.detail = new RecallDetail(detail);
                this.render();
            })
    }

    disconnectedCallback() {
        document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.changeLanguageListener);
        document.removeEventListener(this.shareEvent, this.handleOnShare);
    }

    get translationFile() {
        return './views/Detail/i18n.json'
    }

    get cssFile() {
        return './views/Detail/style.css';
    }

    getAddress() {
        if ((this.detail === null) || (this.detail !== null && this.detail.address === null))
            return '';
        if (this.isAddressVisible(this.detail.address)) {
            return html`
                <div class="product_section">
                    <h4>${this.i18n.translate('detail.address.title')}</h4>
                    <p>${this.detail.address.text}</p>
                    ${this.getTelephone(this.detail.address)}
                    ${this.getEmail(this.detail.address)}
                    ${this.getUrl(this.detail.address)}
                </div>
            `
        }
        return ''
    }

    isAddressVisible(address) {
        if (!address) {
            return false;
        }

        if ((address.text !== null && typeof address.text != "undefined" && address.text.length > 0)
            || (address.telephone !== null && typeof address.telephone != "undefined" && address.telephone.length > 0)
            || (address.email !== null && typeof address.email != "undefined" && address.email.length > 0)
            || (address.url !== null && typeof address.url != "undefined" && address.url.length > 0)
        ) {
            return true;
        }
        return false;
    }

    getAddressMab() {
        if ((this.detail === null) || (this.detail !== null && this.detail.addressMab === null))
            return '';
        if (this.isAddressVisible(this.detail.addressMab)) {
            return html`
                <div class="product_section">
                    <h4>${this.i18n.translate('detail.address.mab.title')}</h4>
                    <p>${this.detail.addressMab.text}</p>
                    ${this.getTelephone(this.detail.addressMab)}
                    ${this.getEmail(this.detail.addressMab)}
                    ${this.getUrl(this.detail.addressMab)}
                </div>
            `
        }
        return ''
    }

    getTelephone(address) {
        if (address.telephone) {
            return html`
                <p>${this.i18n.translate('detail.address.telephone')} <a
                        href="tel:${address.telephone}">${address.telephone}</a></p>
            `
        }
        return ''
    }

    getEmail(address) {
        if (address.email) {
            return html`
                <p>${this.i18n.translate('detail.address.email')} <a href="mailto:${address.email}">${address.email}</a>
                </p>
            `
        }
        return ''
    }

    getUrl(address) {
        if (address.url) {
            return html`
                <p>${this.i18n.translate('detail.address.url')} <a href="${address.url}"
                                                                   target="_blank">${address.url}</a></p>
            `
        }
        return ''
    }

    onShareListener(ev) {
        console.log('onsharelistener', ev)
        send(this.shareEvent, ev);
    }

    validImageFormat(url)  {
        if (url === null || url === undefined || url === "") {
            return false;
        }
        let lcUrl = url.toLowerCase();
        return lcUrl.endsWith('.jpg') || lcUrl.endsWith('.jpeg') || lcUrl.endsWith('.png');
    }

    containSliderImages() {
        let valid = false;
        this.detail
            .documents
            .every(doc => {
                valid = this.validImageFormat(doc.url);
                return !valid;
            })
        return valid;
    }

    getTemplate() {
        if (!this.detail) {
            return ''
        }
        return html`
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/item.css"
                  integrity="sha256-ft01ThhLZvhEWV+NlonSw+vAuFk7DBbufwIi/TvA15I="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
                  integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/button.css"
                  integrity="sha256-wpzJilWuuuCiST+1kQ6j3vLpVZT7CVZ2WsVtcdA5cKE="
                  crossorigin="anonymous">

            <div class="ui items">
                <div
                        class="item">
                    <div class="image">
                        <img class="${this.containSliderImages() ? 'detail_image' : 'detail_image_disabled'}"
                             alt="${this.detail.image.alt}"
                             src="${this.detail.image.url}"
                             @click="${event => location.hash = '#Images/' + this.id}"
                        >
                    </div>
                    <div class="content">
                        <a class="header">${this.detail.header}</a>
                        <div class="meta">
                            <span>${this.detail.meta}</span>
                        </div>
                        <div class="description">
                            <p>${this.detail.description}</p>
                        </div>
                        <div class="extra">
                            <button class="share_button" @click="${evt => this.whatsappHandler(evt)}">
                                <i style="margin:0px;" class="whatsapp icon"></i>
                            </button>
                            <button class="share_button" @click="${evt => this.emailHandler(evt)}">
                                <i style="margin:0px;" class="envelope outline icon"></i>
                            </button>
                            <button class="share_button" @click="${evt => this.twitterHandler(evt)}">
                                <i style="margin:0px;" class="twitter icon"></i>
                            </button>
                            <button class="share_button" @click="${evt => this.facebookHandler(evt)}">
                                <i style="margin:0px;" class="facebook icon"></i>
                            </button>
                            <button class="share_button" @click="${() => this.copyHandler()}">
                                <i style="margin:0px;" class="copy outline icon"></i>
                            </button>
                            <button class="share_button" @click="${this.onShareListener.bind(this)}">
                                <i style="margin:0px;" class="share alternate icon"></i>
                            </button>
                        </div>
                        ${this.getGefahr()}
                        ${this.getProdukt()}
                        ${this.getAktion()}
                        ${this.getAddress()}
                        ${this.getAddressMab()}
                        ${this.getLinks()}
                        ${this.getDokumente()}
                        ${this.getDisclaimer()}
                        <p style="line-height: 3em">&nbsp;</p>
                    </div>
                </div>
            </div>
        `
    }

    copyHandler() {
        const el = document.createElement('textarea');
        el.value = this.copyContent;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    emailHandler(evt) {
        window.open('mailto:'
            + this.emailTo
            + '?subject=' + encodeURI(this.emailSubject)
            + '&body=' + encodeURI(this.emailBody), '_blank');

        this.addMatomoTrackEvent('Email', this.emailBody);
        evt.preventDefault();
    }

    get emailTo() {
        return 'replace@me.com';
    }

    get emailSubject() {
        return this.messageTitle;
    }

    get emailBody() {
        return this.messageBody;
    }

    get messageBody() {
        const description = (this.detail.description) ? this.detail.description : '';
        const url = location.href;
        return description + '\n' + url;
    }

    get messageTitle() {
        return (this.detail.header) ? this.detail.header : '';
    }

    get copyContent() {
        return location.href;
    }

    twitterHandler(evt) {
        window.open(this.twitterUrl, '_blank');
        this.addMatomoTrackEvent('Twitter', this.twitterContent);
        evt.preventDefault();
    }

    get twitterUrl() {
        return 'https://twitter.com/intent/tweet?text='
            + encodeURIComponent(this.twitterContent)
            + '&url=' + encodeURIComponent(location.href);
    }

    get twitterContent() {
        return this.messageTitle;
    }

    facebookHandler(evt) {
        window.open(this.facebookDesktopUrl, '_blank');
        this.addMatomoTrackEvent('Facebook', this.facebookContent);
        evt.preventDefault();
    }

    get facebookDesktopUrl() {
        return 'http://www.facebook.com/sharer/sharer.php'
            + '?u=' + encodeURIComponent(location.href)
            + '&quote=' + encodeURIComponent(this.facebookContent);

    }

    get facebookContent() {
        return this.messageTitle;
    }

    whatsappHandler(evt) {
        if (this.isMobileOrTablet) {
            location.href = this.whatsappMobileOrTabletUrl;
        } else {
            window.open(this.whatsappDesktopUrl, '_blank');
        }
        this.addMatomoTrackEvent('Whatsapp', this.messageTitle);
        evt.preventDefault();
    }

    get whatsappMobileOrTabletUrl() {
        return 'whatsapp://send?text=' + encodeURIComponent(this.whatsappContent);
    }

    get whatsappDesktopUrl() {
        return 'https://web.whatsapp.com/send?text=' + encodeURIComponent(this.whatsappContent);
    }

    get whatsappContent() {
        return this.messageTitle + '\n' + location.href;
    }

    getGefahr() {
        if (this.detail.danger) {
            return html`
                <div class="product_section">
                    <h4>${this.i18n.translate('detail.danger.title')}</h4>
                    <p .innerHTML="${this.detail.danger}"></p>
                </div>
            `
        }
        return ''
    }

    getProdukt() {
        if (this.detail.product) {
            return html`
                <div class="product_section">
                    <h4>${this.i18n.translate('detail.product.title')}</h4>
                    <p .innerHTML="${this.detail.product}"></p>
                </div>
            `
        }
        return ''
    }

    getAktion() {
        if (this.detail.action) {
            return html`
                <div class="product_section">
                    <h4>${this.i18n.translate('detail.action.title')}</h4>
                    <p .innerHTML="${this.detail.action}"></p>
                </div>
            `
        }
        return ''
    }

    getLinks() {
        if (!this.detail.links) return '';
        const links = this.getFilteredLinks(this.detail.links);
        if (links.length > 0) {
            return html`
                <div>
                    <h4>${this.i18n.translate('detail.link.title')}</h4>
                    <ul>
                        ${links.map(l => html`
                            <li><a href="${l.url}">${l.text}</a></li>`)}
                    </ul>
                </div>
            `
        }
        return ''
    }

    getFilteredLinks(links) {
        if (!links) return [];
        return links.filter(p => typeof p.text != "undefined" && p.text.length > 0);
    }

    getDokumente() {
        if (!this.detail.documents) return '';
        const documents = this.getFilteredDokumente(this.detail.documents);
        if (documents.length > 0) {
            return html`
                <div class="product_section">
                    <h4>${this.i18n.translate('detail.download.title')}</h4>
                    <ul>
                        ${documents.map(d => html`
                            <li><a href="${d.url}">${d.text}</a></li>`)}
                    </ul>
                </div>
            `
        }
        return ''
    }

    getFilteredDokumente(dokumente) {
        if (!dokumente) return [];
        return dokumente.filter(p => typeof p.text != "undefined" && p.text.length > 0);
    }

    getDisclaimer() {
        if (this.detail.disclaimer) {
            return html`
                <div class="product_section">
                    <h4>${this.i18n.translate('detail.disclaimer.title')}</h4>
                    <p>${this.detail.disclaimer}</p>
                </div>
            `
        }
        return ''
    }

    customEncoding(url) {
        return url.replace('#', '');
    }

    addMatomoTrackEvent(sharedTyp, content) {
        // matomo specific event to track the sharing of a recall
        if (_paq !== null && typeof (_paq) !== 'undefined') {
            _paq.push(['trackEvent', 'Sharing', sharedTyp, content]);
        }
    }
}

customElements.define('customer-access-detail', Detail)