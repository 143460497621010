import './isceco-polyfills.js'
import './views/MainView/MainView.js'
import './views/Navigation/Navigation.js'
import './views/QuickNavigation/QuickNavigation.js'
import './views/Footer/Footer.js'
import './views/SearchBar/SearchBar.js'

fetch('./config/config.json')
    .then(res => res.json())
    .then(res => {
        let homeLink = document.querySelectorAll(".homelink")
        homeLink.forEach(node => {
            node.href = res.customerHomeUrl
        })
    }).catch(error => {
        console.log('config error', error)
    })