import WebComponent from '../../WebComponent.js';
import I18n from '../../i18n.js';
import QuickNavigation from '../QuickNavigation/QuickNavigation.js';


export default class Shared extends WebComponent {

    connectedCallback() {
        this.changeLanguageListener = () => {
            this.render();
        };
        this.wirtschaftszweigFilterChangedListener = e => {
            sessionStorage.setItem('wirtschaftszweigId', e.detail);
            window.location.hash = 'Recalls';
        };
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.changeLanguageListener);
        document.addEventListener(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.wirtschaftszweigFilterChangedListener);
    }

    disconnectedCallback() {
        document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.changeLanguageListener);
        document.removeEventListener(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.wirtschaftszweigFilterChangedListener);
    }

}