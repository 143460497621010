import { html } from '../../_snowpack/pkg/lit-html.js';
import Shared from '../Shared/Shared.js';

export default class Installer extends Shared {

    get translationFile() {
        return './views/Installer/i18n.json'
    }

    get cssFile() {
        return './views/Installer/style.css';
    }

    getTemplate() {
        return html`
          <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
          integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
          crossorigin="anonymous">
          <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/button.css"
          integrity="sha256-wpzJilWuuuCiST+1kQ6j3vLpVZT7CVZ2WsVtcdA5cKE="
          crossorigin="anonymous">
<div class="installer">
    ${this.androidDescription}
    <div class="divider"></div>
    ${this.iosDescription}
    <button
        @click="${_ => window.location.hash = 'Recalls'}"
        class="ui button"><i class="home icon"></i> ${this.i18n.translate('installer.back')}</button>
        <p>&nbsp</p>
</div>
        `
    }

    get androidDescription() {
        return html`
            <article>
                <h3>${this.i18n.translate('installer.operating.system.android')}</h3>
                <p>${this.i18n.translate('installer.description.android')}</p>
            </article>
        `;
    }

    get iosDescription() {
        return html`
            <article>
                <h3>${this.i18n.translate('installer.operating.system.ios')}</h3>
                <p>
                    ${this.i18n.translate('installer.description.ios.line.one.part.one')}
                    <a href="/customer-access/">
                        ${this.i18n.translate('installer.description.ios.line.one.part.two')}
                    </a>
                    <br>
                    ${this.i18n.translate('installer.description.ios.line.two')}
                    <br>
                    <img style="margin-top:10px;" src='images/button_share_blue.svg' width='30' height='30' alt='[↑]'>
                    <br>
                    ${this.i18n.translate('installer.description.ios.line.three')}
                    <br>
                    <img style="margin-top:10px;" src='images/button_home_orig.svg' width='30' height='30' alt='[+]'>
                    <br>
                    ${this.i18n.translate('installer.description.ios.line.four')}
                </p>
            </article>
        `;
    }
}

customElements.define('customer-access-installer', Installer)

