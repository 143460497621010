if (!window.send) {
    window.send = (name, payload) => {
        document.dispatchEvent(new CustomEvent(name, {
            detail: payload,
            bubbles: true
        }))
    }
}

if (!window.setQueryParams) {
    window.setQueryParams = (pageQueryParam, searchQueryParam, hashNotPresent=true) => {
        if(pageQueryParam || searchQueryParam) {
            const params = new URLSearchParams(window.location.hash.split('?')[1]);
            if(!isEmpty(pageQueryParam)) {
                // set or override
                params.set('page',pageQueryParam.split('=')[1])
            }
            if(!isEmpty(searchQueryParam)) {
                const value = searchQueryParam.split('=')[1]
                if(isEmpty(value)) {
                    params.delete('search')
                } else {
                    // set or override
                    params.set('search',searchQueryParam.split('=')[1])
                }
            }
            let hash = getNavigationHash()
            hash = isEmpty(hash) ? '?'+params.toString() : '#'+hash.split('?')[0]+'?'+params.toString()
            const baseUrl = location.origin + location.pathname
            const url = `${baseUrl}${hash}`
            history.replaceState(null, null, url)
        }
    }
}

if (!window.getQueryParams) {
    window.getQueryParams = () => new URLSearchParams(window.location.href.split('?')[1])
}


if (!window.getNavigationHash) {
    window.getNavigationHash = () => {
        return window.location.hash.substr(1)
    }
}

if (!window.getNavigationId) {
    window.getNavigationId = () => window.location.hash.split('?')[0].split('/')[1]
}

if (!window.setLanguage) {
    window.setLanguage = (lang) => {
        localStorage.setItem('ch.admin.customer-access.language', lang);

        // matomo specific event to track the language selections
        if(_paq !== null && typeof(_paq) !== 'undefined') {
            _paq.push(['trackEvent', 'Sprache', 'Sprachselektion', lang]);
        }
    }
}

if (!window.getLanguage) {
    window.getLanguage = () => {
        let lang = localStorage.getItem('ch.admin.customer-access.language');
        if (!lang) {
            lang = navigator.language || navigator.userLanguage;
            lang = lang.substring(0, 2);
            if (lang !== 'de' && lang !== 'fr' && lang !== 'it') {
                lang = 'de';
            }
        }
        return lang;
    }
}

if (!window.isEmpty) {
    window.isEmpty = str => str === undefined || str === null || str === ''
}

if (!window.isLocalDevelopment) {
    window.isLocalDevelopment = () => {
        return localStorage.getItem('ch.admin.customer-access.local.dev');
    }
}
