import BaseService from '../../BaseService.js';

export default class WarnsubscriptionService extends BaseService {

    constructor() {
        super('warnsubscriptions')
    }

    async sendUnsubscribeMessage(queryParam) {
        await this.retrieveCommandUrl();
        let url = this.commandUrl + '/' + 'sendUnsubscribeMessage';
        url = this.getParameterizedUrl(url, queryParam);

        return fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(result => result)
        .catch(error => {
            this.logging.log(error);
        });
    }
}