import WebComponent from '../../WebComponent.js';
import {html} from '../../_snowpack/pkg/lit-html.js';
import WirtschaftszweigService from './WirtschaftszweigService.js';

export default class QuickNavigation extends WebComponent {

    static get EVENT_KEYS() {
        return {
            WIRTSCHAFTSZWEIG_FILTER_CHANGED: 'wirtschaftszweig-filter-changed',
            DEACTIVATE_FILTER_FLAG: 'deactivate_filter_flag'
        }
    }

    connectedCallback() {
        this.service = new WirtschaftszweigService()
        this.selectedWz = null
        this.service.retrieveAll()
            .then(wirtschaftszweige => {
                this.wirtschaftszweige = wirtschaftszweige
                this.render()
            })
        this.deactivateFilterFlagListener = () => {
            this.deactivateFilterFlag();
        }
        document.addEventListener(QuickNavigation.EVENT_KEYS.DEACTIVATE_FILTER_FLAG, this.deactivateFilterFlagListener);
    }

    disconnectedCallback() {
        document.addEventListener(QuickNavigation.EVENT_KEYS.DEACTIVATE_FILTER_FLAG, this.deactivateFilterFlagListener);
    }

    get cssFile() {
        return './views/QuickNavigation/style.css';
    }

    onFilterClick(wzId) {
        if (this.selectedWz === wzId) {
            this.selectedWz = null
        } else {
            this.selectedWz = wzId
        }
        send(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.selectedWz)
        this.render();
    }

    deactivateFilterFlag() {
        this.selectedWz = null
        this.render();
    }

    toFilterEntry(wirtschaftszweig) {
        return html`
            <a
                id="${wirtschaftszweig.id}"
                @click="${_ => this.onFilterClick(wirtschaftszweig.id)}"
                onclick="_paq.push(['trackEvent', 'Filter', 'Wirtschaftszweig', 'WirtschaftszweigId',${this.selectedWz}]);"
                class="item${(this.selectedWz === wirtschaftszweig.id) ? ' active' : ''}"
                >
                <i class="${wirtschaftszweig.iconName} icon"></i>
            </a>
       `
    }

    getTemplate() {
        return html`
        <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/menu.css"
          integrity="sha256-QXVhP5B9h80o2da7dZ1P0o/iU6y/q26xiudmCtphp5k="
          crossorigin="anonymous">
          <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
          integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
          crossorigin="anonymous">
        <div class="ui five item menu">
            ${this.wirtschaftszweige
            .map(wirtschaftszweig => this.toFilterEntry(wirtschaftszweig))}
        </div>
        `
    }
}

customElements.define('customer-access-quick-navigation', QuickNavigation)