import WebComponent from '../../WebComponent.js';
import { html } from '../../_snowpack/pkg/lit-html.js';

export default class Navigation extends WebComponent {

    static get EVENT_KEYS() {
        return {
            NAVIGATION_HAPPEND: 'customer-access-navigation-happend',
            NAVIGATION_CLOSED: 'customer-access-navigation-closed',
            NAVIGATION_OPENED: 'customer-access-navigation-opened'
        }
    }

    connectedCallback() {
        window.onhashchange = _ => this.onAddressBarChanged()

        window.onclick = e => {
            if (e.view.innerWidth - e.clientX > 250) {
                this.minimizeNavigation();
            }
        }

        this.render();
    }

    get translationFile() {
        return './views/Navigation/i18n.json'
    }

    get cssFile() {
        return './views/Navigation/style.css';
    }

    onAddressBarChanged() {
        send(Navigation.EVENT_KEYS.NAVIGATION_HAPPEND, getNavigationHash())
        this.render()
    }

    toggleNavigation() {
        let sidebar = this.root.getElementById("mySidebar");
        if (sidebar.style.width === "250px") {
            this.minimizeNavigation()
        } else {
            sidebar.style.width = "250px";
            send(Navigation.EVENT_KEYS.NAVIGATION_OPENED, {
                width: '250px'
            })
        }
    }

    minimizeNavigation() {
        let sidebar = this.root.getElementById("mySidebar");
        sidebar.style.width = "0";
        send(Navigation.EVENT_KEYS.NAVIGATION_CLOSED, {
            width: '250px'
        })
    }

    minimizeNavigationAndReload() {
        this.minimizeNavigation();
        this.onAddressBarChanged();
    }

    getTemplate() {
        return html`
<div id="mySidebar" class="sidebar">
    <div style="display:block; text-align:right">
        <button
            class="closebtn"
            id="menuButton"
            @click="${_ => this.minimizeNavigation()}"
            >X</button>
    </div>
        <a
        @click="${_ => this.minimizeNavigationAndReload()}"
            href="#Recalls"
            id="recalls-link">
            ${this.i18n.translate('navigation.home')}
        </a>
        <a
            @click="${_ =>  this.minimizeNavigation()}"
            href="#Search"
            id="search-link">
            ${this.i18n.translate('navigation.search')}
        </a>
        <a
            href="${this.i18n.translate('navigation.report.link')}"
            id="report-link">
            ${this.i18n.translate('navigation.report')}
        </a>
        <a
            @click="${_ => this.minimizeNavigation()}"
            href="#WarningSubscription"
            id="warningSubscription-link">
            ${this.i18n.translate('navigation.warningService')}
        </a>
        <a
            @click="${_ => this.minimizeNavigation()}"
            href="#Language"
            id="language-link">
            ${this.i18n.translate('navigation.language')}
        </a>
        <a
            @click="${_ => this.minimizeNavigation()}"
            href="#Impressum"
            id="impressum-link">
            ${this.i18n.translate('navigation.imprint')}
        </a>
        <a
            @click="${_ => this.minimizeNavigation()}"
            href="#Installer"
            id="installer-link">
            ${this.i18n.translate('navigation.installer')}
        </a>
</div>
  <button  class="openbtn" id="menuButton" @click="${_ => this.toggleNavigation()}">☰</button>
        `
    }

}

customElements.define('customer-access-navigation', Navigation)