import WebComponent from '../../WebComponent.js';
import I18n from '../../i18n.js';
import RecallService from '../Recalls/RecallService.js';
import RecallDetail from './RecallDetail.js';
import {html} from '../../_snowpack/pkg/lit-html.js';
import '../../_snowpack/pkg/@isceco/widget-library/build/basic-elements/Carousel/Carousel.js';

export default class Slider extends WebComponent {

    constructor(id) {
        super();
        this.id = id;
        this.isMobileOrTablet = screen.width < 950 ? true : false;
    }

    connectedCallback() {
        this.detail = null;
        this.changeLanguageListener = () => {
            this.render();
        };
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.changeLanguageListener);
        new RecallService()
            .retrieve(this.id)
            .then(detail => {
                this.detail = new RecallDetail(detail);
                this.render();
            })
    }

    disconnectedCallback() {
        document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.changeLanguageListener);
    }

    get translationFile() {
        return './views/Detail/i18n.json'
    }

    get cssFile() {
        return './views/Detail/slider.css';
    }

    images() {
        const images = []

        this.detail
            .documents
            .forEach(doc => {
                if (this.validImageFormat(doc.url)) {
                    images.push({"image": `${doc.url}`, "id": `${doc.text}`})
                }
            })

        return JSON.stringify(images)
    }

    validImageFormat(url)  {
        if (url === null || url === undefined || url === "") {
           return false;
        }
        let lcUrl = url.toLowerCase();
        return lcUrl.endsWith('.jpg') || lcUrl.endsWith('.jpeg') || lcUrl.endsWith('.png');
    }

    getTemplate() {
        if (!this.detail) {
            return ''
        }
        return html`
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/item.css"
                  integrity="sha256-ft01ThhLZvhEWV+NlonSw+vAuFk7DBbufwIi/TvA15I="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
                  integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/button.css"
                  integrity="sha256-wpzJilWuuuCiST+1kQ6j3vLpVZT7CVZ2WsVtcdA5cKE="
                  crossorigin="anonymous">

            <div class="closeBtn">
                 <a href="#Recalls/${this.id}">
                    <i class="window close icon"></i>
                 </a>
            </div>
            <div class="ui items">
                <div class="item">
                    <div class="content">
                        <isceco-carousel
                                items=${this.images()}>
                        </isceco-carousel>
                    </div>
                </div>
            </div>
        `
    }
}

customElements.define('customer-access-detail-slider', Slider)