export default class I18n {

    static get EVENT_KEYS() {
        return {
            CHANGE_LANGUAGE: 'customer-access-language-changed'
        }
    }

    constructor() {
        this.translations = {};
    }

    async loadConfiguration(path) {
        return await fetch(path, {
            credentials: 'same-origin',
            cache: 'reload',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(i18n => {
                this.translations = i18n;
            })
    }

    translate(key, values) {
        const currentLanguage = this.translations[getLanguage()];
        let translatedText = currentLanguage ? currentLanguage[key] : null;
        if (translatedText && values) {
            Object.keys(values).forEach(key => {
                translatedText = translatedText.replace(`~~${key}~~`, values[key]);
            });
        }
        return translatedText || key;
    }
}