export default class Address {

    constructor(json) {
        this.json = json;
    }

    get telephone() {
        if (getLanguage() === 'fr') {
            return this.json.telephoneFr;
        } else if (getLanguage() === 'it') {
            return this.json.telephoneIt;
        } else {
            return this.json.telephoneDe;
        }
    }

    get url() {
        if (getLanguage() === 'fr') {
            return this.json.urlFr;
        } else if (getLanguage() === 'it') {
            return this.json.urlIt;
        } else {
            return this.json.urlDe;
        }
    }

    get email() {
        if (getLanguage() === 'fr') {
            return this.json.emailFr;
        } else if (getLanguage() === 'it') {
            return this.json.emailIt;
        } else {
            return this.json.emailDe;
        }
    }

    get text() {
        if (getLanguage() === 'fr') {
            return this.json.textFr;
        } else if (getLanguage() === 'it') {
            return this.json.textIt;
        } else {
            return this.json.textDe;
        }
    }
}