import Link from './Link.js';
import Document from './Document.js';
import Address from './Address.js';
import Recall from '../Recalls/Recall.js';
import QuickNavigation from '../QuickNavigation/QuickNavigation.js';

export default class RecallDetail {

    constructor(json) {
        this.json = json;
        this.recall = new Recall(json);

        this.id = json.id;
        this.image = this.recall.image;

        this.address = json.address ? new Address(json.address) : null
        this.addressMab = json.addressMab ? new Address(json.addressMab) : null
        this.links = json.links
            .map(l => new Link(l));
        this.documents = json.documents
            .map(d => new Document(d));

        this.wirtschaftszweigFilterChangedListener = e => {
            sessionStorage.setItem('wirtschaftszweigId', e.detail);
            window.location.hash = 'Recalls';
        };
        document.addEventListener(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.wirtschaftszweigFilterChangedListener);
    }

    disconnectedCallback() {
        document.removeEventListener(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.wirtschaftszweigFilterChangedListener);
    }

    get header() {
        return this.recall.header
    }

    get meta() {
        return this.recall.meta
    }

    get description() {
        return this.recall.description
    }

    get danger() {
        if (getLanguage() === 'fr') {
            return this.json.dangerFr;
        } else if (getLanguage() === 'it') {
            return this.json.dangerIt;
        } else {
            return this.json.dangerDe;
        }
    }

    get product() {
        if (getLanguage() === 'fr') {
            return this.json.productFr;
        } else if (getLanguage() === 'it') {
            return this.json.productIt;
        } else {
            return this.json.productDe;
        }
    }

    get action() {
        if (getLanguage() === 'fr') {
            return this.json.actionFr;
        } else if (getLanguage() === 'it') {
            return this.json.actionIt;
        } else {
            return this.json.actionDe;
        }
    }

    get disclaimer() {
        if (getLanguage() === 'fr') {
            return this.json.disclaimerFr;
        } else if (getLanguage() === 'it') {
            return this.json.disclaimerIt;
        } else {
            return this.json.disclaimerDe;
        }
    }
}