export default class LoggingService {

    retrieveConfiguration() {
        return fetch('./config/config.json')
            .then(res => res.json());
    }

    retrieveQueryUrl() {
        return this.retrieveConfiguration()
            .then(config => {
                this.queryUrl = `${config.queryUrl}/customer-access/resources/logs`;
            })
            .catch(error => {
                this.log(error);
            });
    }

    async retrieveAll() {
        //console.log('retrieve all logs')
        await this.retrieveQueryUrl();
        return fetch(this.queryUrl)
            .then(result => result.json())
            .catch(error => {
                this.log(error);
            });
    }

    async post(logs) {
        //console.log('post logs')
        await this.retrieveQueryUrl();
        return fetch(this.queryUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(logs)
        })
    }

    log(error) {
        console.error(error);
    }
    // eaib: temporarely silence logging messages until completion
    log_disabled(error) {

        let entry = {entry: error.message, stack: error.stack};
        this.post(entry)
            .then(res => {
                if (res.status < 200 || res.status > 300) {
                    console.error('could not log: ', error)
                }
                console.warn(error);
            })
            .catch(error => {
                console.error(error);
            })
    }
}
