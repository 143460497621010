import {html} from '../../_snowpack/pkg/lit-html.js';
import {BarcodeFormat, DecodeHintType, NotFoundException} from "../../_snowpack/pkg/@zxing/library.js";
import {BrowserCodeReader, BrowserMultiFormatReader} from "../../_snowpack/pkg/@zxing/browser.js";
import WebComponent from '../../WebComponent.js';

export default class BarcodeScanner extends WebComponent {

    constructor() {
        super();

        this.errorMsg = '';
    }

    async connectedCallback() {
        this.render();
        await this.initReader();
        await this.initCameraSelector();
        await this.startScanning();
    }

    async disconnectedCallback() {
        await this.stopScanning();
    }

    getTemplate() {
        if (this.errorMsg !== '') {
            return html`
                <p id="error">${this.errorMsg}</p>
            `;
        }

        return html`
            <div id="wrapper">
                <video autoplay id="preview"></video>
                <select id="cameras-select"
                        name="cameras"
                        @change="${_ => this.restartScanning()}">
                </select>
            </div>
        `;
    }

    get cssFile() {
        return './views/BarcodeScanner/style.css';
    }

    get translationFile() {
        return './views/BarcodeScanner/i18n.json';
    }

    async initReader() {
        const hints = new Map();
        hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.EAN_13, BarcodeFormat.DATA_MATRIX]);
        hints.set(DecodeHintType.ASSUME_GS1, true);

        this.reader = new BrowserMultiFormatReader(hints);

        this.inputDevices = await BrowserCodeReader.listVideoInputDevices();
    }

    async initCameraSelector() {
        if (this.inputDevices.length === 0){
            this.setNoCameraFound();
            this.render();
            return;
        }

        if (this.inputDevices.length === 1)
            this.root.getElementById('cameras-select').classList.add('hidden');

        this.inputDevices.forEach(device => {
            this.addDeviceToCameraSelector(device);
        });

        this.selectBestCamera();
    }

    setNoCameraFound() {
        this.errorMsg = this.i18n.translate('no.camera.found')
    }

    addDeviceToCameraSelector(device) {
        const optionElement = document.createElement('option');
        optionElement.textContent = device.label;
        optionElement.value = device.deviceId ? device.deviceId : 'undefined';
        this.root.getElementById('cameras-select').appendChild(optionElement);
    }

    selectBestCamera() {
        const selector = this.root.getElementById('cameras-select');

        if (selector.options.length < 2)
            return;

        for (let i = 0; i < selector.options.length; i++) {
            const option = selector.options[i];
            const label = option.innerHTML;
            if (label.includes('back')) {
                selector.selectedIndex = i;
                return;
            }
        }
    }

    async startScanning() {
        if (this.isErrorOccurred()){
            return;
        }

        const previewElement = this.root.getElementById('preview')

        let deviceId = this.root.getElementById('cameras-select').value;
        if (deviceId === 'undefined') deviceId = undefined;

        this.controls = await this.reader.decodeFromVideoDevice(
            deviceId,
            previewElement,
            (result, error, controls) => {
                if (result) {
                    this.dispatchEventFound(result.getText())
                    this.stopScanning();
                }
                if (error && !(error instanceof NotFoundException)) {
                    console.error(error);
                    this.errorMsg =error.message;
                }
                if (controls) {
                    if (this.isErrorOccurred())
                        controls.stop();
                }
            });

        setTimeout(() => this.controls.stop(), 30_000);

    }

    dispatchEventFound(text) {
        const event = new CustomEvent(BARCODE_FOUND_EVENT, {
            bubbles: true,
            detail: {text}
        });
        document.dispatchEvent(event);
    }

    stopScanning() {
        this.controls?.stop();
    }

    async restartScanning() {
        this.stopScanning();
        await this.startScanning();
    }

    clearErrorMsg() {
        this.errorMsg = '';
    }

    isErrorOccurred() {
        return this.errorMsg !== '';
    }
}

customElements.define('barcode-scanner', BarcodeScanner);

export const BARCODE_FOUND_EVENT = 'barcode-found-event';