import WebComponent from '../../WebComponent.js';
import {html} from '../../_snowpack/pkg/lit-html.js';

export default class NoContent extends WebComponent {

    connectedCallback() {
        this.render();
    }

    get cssFile() {
        return './views/NoContent/style.css';
    }

    getTemplate() {
        return html`
        <div>
            <img
                src="https://media.giphy.com/media/5f2mqsGTHpe5a/giphy.gif"
                alt="not found">
        </div>
        `
    }
}

customElements.define('customer-access-no-content', NoContent)