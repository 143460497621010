import WebComponent from '../../../WebComponent.js';
import I18n from '../../../i18n.js';
import { html } from '../../../_snowpack/pkg/lit-html.js';
import SharedContent from "../../Shared/SharedContent.js"

export default class WarningSubscriptionConfirmation extends WebComponent {

    constructor() {
        super();
    }

    connectedCallback() {
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, _ => {
            this.render();
        });
        this.message = sessionStorage.getItem("message");
        this.render();
    }

    get translationFile() {
        return './views/WarningSubscription/Confirmation/i18n.json'
    }

    get cssFile() {
        return './views/WarningSubscription/Confirmation/style.css';
    }

    getTemplate() {
        return html`
            <div class="main_wrapper">
                <div>${this.message}</div>
                <div>${new SharedContent().getBackHomeButton(
                    this.i18n.translate('warning.subscription.confirmation.back')
                )}</div>
            </div>
        `
    }
}
customElements.define('customer-access-warning-subscription-confirmation', WarningSubscriptionConfirmation)