import WebComponent from '../../WebComponent.js';
import "../BarcodeScanner/BarcodeScanner.js";
import {html} from '../../_snowpack/pkg/lit-html.js'
import {BARCODE_FOUND_EVENT} from "../BarcodeScanner/BarcodeScanner.js";

export default class SearchBar extends WebComponent {

    static get EVENT_KEYS() {
        return {
            SEARCH_CHANGED: 'search-changed'
        }
    }

    // component attributes
    static get observedAttributes() {
        return ['text'];
    }

    // attribute change
    attributeChangedCallback(property, oldValue, newValue) {
        if (oldValue === newValue) return;
        this['text'] = newValue === 'null' ? '' : newValue;
    }

    connectedCallback() {
        this.render();

        this.scannerOpen = false;

        document.addEventListener(BARCODE_FOUND_EVENT, e => this.barcodeFoundListener(e));
    }

    get translationFile() {
        return './views/SearchBar/i18n.json'
    }

    get cssFile() {
        return './views/SearchBar/style.css';
    }

    getTemplate() {
        return html`
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/divider.css"
                  integrity="sha256-5Vp89DiwHtBSkiLE+c/FyeFJCCHKNYLGPDblXAuj4Ng="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
                  integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/divider.css"
                  integrity="sha256-5Vp89DiwHtBSkiLE+c/FyeFJCCHKNYLGPDblXAuj4Ng="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/button.css"
                  integrity="sha256-wpzJilWuuuCiST+1kQ6j3vLpVZT7CVZ2WsVtcdA5cKE="
                  crossorigin="anonymous">
            <div id="customer-search">
                <p>${this.i18n.translate('recalls.search.intro')}</p>
                <div id="searchbar">
                    <a id="search_button" class="item">
                        <i class="search icon"></i>
                    </a>
                    <input id="search"
                           type="search"
                           value="${this['text']}"
                           @change="${e => {
                               const searchParam = 'search=' + e.target.value
                               setQueryParams('page=1', searchParam, isEmpty(getNavigationHash()))
                               send(SearchBar.EVENT_KEYS.SEARCH_CHANGED, e.target.value)
                           }}"
                    />
                    <button class="ui button mini"
                            id="show-scanning"
                            style="padding-top: 0.4rem; padding-bottom: 0.4rem"
                            @click=${_ => this.toggleScanning()}
                    ><i class="barcode icon large" style="margin: 0 !important"></i>
                    </button>
                </div>
                ${this.scannerOpen ? html`
                    <barcode-scanner></barcode-scanner>` : ''}
                <div class="ui divider"></div>
            </div>
        `
    }

    triggerSearch(searchString) {
        console.log('trigger search with :', searchString);
        const searchParam = 'search=' + searchString;
        setQueryParams('page=1', searchParam, isEmpty(getNavigationHash()));
        send(SearchBar.EVENT_KEYS.SEARCH_CHANGED, searchString);

    }

    async toggleScanning() {
        this.scannerOpen = !this.scannerOpen;
        this.render();
    }

    barcodeFoundListener = ({detail: {text}}) => {
        const barcodeValueWithoutGS1SpecialChar = text.replace("\u001d", "");
        this.toggleScanning();
        this.triggerSearch(barcodeValueWithoutGS1SpecialChar);
    }
}

customElements.define('customer-access-search-bar', SearchBar)