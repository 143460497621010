import Image from './Image.js';

export default class Recall {

    constructor(json) {
        this.json = json;

        this.id = json.id;
        this.image = new Image(json.image);
    }

    get header() {
        if (getLanguage() === 'fr') {
            return this.json.headerFr;
        } else if (getLanguage() === 'it') {
            return this.json.headerIt;
        } else {
            return this.json.headerDe;
        }
    }

    get meta() {
        if (getLanguage() === 'fr') {
            return this.json.metaFr;
        } else if (getLanguage() === 'it') {
            return this.json.metaIt;
        } else {
            return this.json.metaDe;
        }
    }

    get description() {
        if (getLanguage() === 'fr') {
            return this.json.descriptionFr;
        } else if (getLanguage() === 'it') {
            return this.json.descriptionIt;
        } else {
            return this.json.descriptionDe;
        }
    }

    get marktaufsichtsbehoerde() {
        if (!this.json.marktaufsichtsbehoerde) {
            return ''
        }
        if (getLanguage() === 'fr') {
            return this.json.marktaufsichtsbehoerde.kuerzelFr;
        } else if (getLanguage() === 'it') {
            return this.json.marktaufsichtsbehoerde.kuerzelIt;
        } else {
            return this.json.marktaufsichtsbehoerde.kuerzelDe;
        }
    }
}