import { html } from '../../_snowpack/pkg/lit-html.js';

export default class SharedContent {

    getBackHomeButton(caption) {
        return html`
            <link rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
            integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
            crossorigin="anonymous">
            <link rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/button.css"
            integrity="sha256-wpzJilWuuuCiST+1kQ6j3vLpVZT7CVZ2WsVtcdA5cKE="
            crossorigin="anonymous">
            <style>
                .button {
                    background-color: var(--quaternary-color) !important;
                    margin-top: 1em !important;
                    color:white !important;
                }
                .button:hover {
                    color: var(--tertiary-color) !important;
                }
            </style>
            <button
                @click="${_ => window.location.hash = 'Recalls'}"F
                class="ui button">
                    <i class="home icon"></i>
                ${caption}
            </button>`
    }
}