import WebComponent from '../../../WebComponent.js';
import I18n from '../../../i18n.js';
import { html } from '../../../_snowpack/pkg/lit-html.js';
import WarnsubscriptionService from "../WarnsubscriptionService.js";
import SharedContent from "../../Shared/SharedContent.js"

export default class WarningSubscriptionActivationDeactivation extends WebComponent {

    constructor(isActivation) {
        super();
        const token = this.token;
        if (!token) {
            this.message = this.i18n.translate('warning.subscription.activation.deactivation.error.no.token');
            this.render();
        } else {
            setLanguage(this.language === null ? 'de' : this.language);
            this.activateOrDeactivate(token, isActivation);
        }
    }

    connectedCallback() {
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, _ => {
            this.render();
        });
    }

    get translationFile() {
        return './views/WarningSubscription/ActivationDeactivation/i18n.json'
    }

    get cssFile() {
        return './views/WarningSubscription/ActivationDeactivation/style.css';
    }

    getTemplate() {
        return html`
            <div class="main_wrapper">
                <div>${this.message}</div>
                <div>${new SharedContent().getBackHomeButton(
            this.i18n.translate('warning.subscription.activation.deactivation.back')
        )}</div>
            </div>
        `
    }

    get token() {
        const location = window.location.toString();
        const token = /.+?(?=\?otp)\?otp=(.+?(?=\&lang))&lang=/
        const groups = token.exec(location);
        return groups !== null
            ? groups[1]
            : null;
    }

    get language() {
        const location = window.location.toString();
        const token = /.+?(?=lang=)lang=(.{2})/
        const groups = token.exec(location);
        return groups !== null
            ? groups[1]
            : null;
    }

    activateOrDeactivate(token, activate) {
        const tokenDTO = {
            "otp": token,
            "active": activate
        }
        const service = new WarnsubscriptionService();
        service.put(tokenDTO)
            .then(res => {
                this.handleResponse(res, activate);
            })
            .catch(error => console.log('ERROR when activating subscription: ', error));
    }

    handleResponse(response, activate) {
        if (response.status === 200) {
            this.handle200Response(activate);
        } else {
            this.handleErrorResponse(activate);
        }
    }

    handle200Response(activate) {
        const subscribedKey = 'warning.subscription.activation.deactivation.just.subscribed';
        const unsubscribedKey = 'warning.subscription.activation.deactivation.just.unsubscribed';
        this.message = activate
            ? this.i18n.translate(subscribedKey)
            : this.i18n.translate(unsubscribedKey);

        if (this.message === '' || this.message === subscribedKey || this.message === unsubscribedKey) {
            this.i18Text.then(i18n => {
                const currentLanguage = this.language === null ? 'de' : this.language
                this.message = activate
                    ? i18n[currentLanguage][subscribedKey]
                    : i18n[currentLanguage][unsubscribedKey]
                this.render();
            });
        } else {
            this.render();
        }
    }

    handleErrorResponse(activate) {
        const errorSubscribedKey = 'warning.subscription.activation.deactivation.error.activation';
        const errorUnsubscribedKey = 'warning.subscription.activation.deactivation.error.deactivation';

        this.message = activate
            ? this.i18n.translate(errorSubscribedKey)
            : this.i18n.translate(errorUnsubscribedKey);

        if (this.message === '' || this.message === errorSubscribedKey || this.message === errorUnsubscribedKey) {
            this.i18Text.then(i18n => {
                const currentLanguage = this.language === null ? 'de' : this.language
                this.message = activate
                    ? i18n[currentLanguage][errorSubscribedKey]
                    : i18n[currentLanguage][errorUnsubscribedKey]
                this.render();
            });
        } else {
            this.render();
        }
    }

    get i18Text() {
        return fetch(this.translationFile, {
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .catch(error => {
                console.log('Error loading translations', error)
            })
    }
}
customElements.define('customer-access-warning-subscription-activate-deactivate', WarningSubscriptionActivationDeactivation)