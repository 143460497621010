import WebComponent from '../../WebComponent.js';
import {html} from '../../_snowpack/pkg/lit-html.js';
import QuickNavigation from '../QuickNavigation/QuickNavigation.js';

export default class Footer extends WebComponent {

    get translationFile() {
        return './views/Footer/i18n.json'
    }

    get cssFile() {
        return './views/Footer/style.css';
    }

    getTemplate() {
        return html`
            <link rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/header.css"
            integrity="sha256-wMi6IY7TBs3eLTkoe/hfGvyxaAI4uCqrLjIWimTHKjQ="
            crossorigin="anonymous">
            <link rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/menu.css"
            integrity="sha256-QXVhP5B9h80o2da7dZ1P0o/iU6y/q26xiudmCtphp5k="
            crossorigin="anonymous">
            <link rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
            integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
            crossorigin="anonymous">

                <footer>
                    <div>
                        <p>
                            <a
                            @click="${() => this.goBack()}"
                            class="item">
                                <i style="font-size: 1.5em;" class="arrow left icon"></i>
                            </a>
                            <a
                            @click="${() => this.goHome()}"
                            style="color: inherit; text-decoration: none;"
                            class="item">
                                <i style="font-size: 1.5em;margin-left: 12px;" class="home icon"></i>
                            </a>
                            <span style="float:right">
                            </span>
                            <a
                            href="#WarningSubscription"
                            style="color: inherit; text-decoration: none;float:right;"
                            class="item">
                                <i style="font-size: 1.5em;margin-left: 12px;" class="bell outline icon"></i>
                            </a>
                            <a
                            href="#Installer"
                            style="color: inherit; text-decoration: none;float:right;"
                            class="item">
                            <i style="font-size: 1.5em;margin-left: 12px;" class="download icon"></i>
                            </a>
                            <a
                            href="${this.i18n.translate('footer.report.link')}"
                            style="color: inherit; text-decoration: none;float:right;"
                            class="item">
                                <i style="font-size: 1.5em; margin-left: 12px;" class="bullhorn icon"></i>
                            </a>
                            <a
                            href="#Search"
                            style="color: inherit; text-decoration: none;float:right;"
                            class="item">
                                <i style="font-size: 1.5em;" class="search icon"></i>
                            </a>

                        </p>
                    </div>
                </footer>
        `
    }

    goBack() {
        window.history.back();
    }

    goHome() {
        send(QuickNavigation.EVENT_KEYS.DEACTIVATE_FILTER_FLAG, null);
        location.href = '/customer-access/';
    }

}

customElements.define('customer-access-footer', Footer)