export default class Document {

    constructor(json) {
        this.json = json;
    }

    get url() {
        if (getLanguage() === 'fr') {
            return this.json.urlFr;
        } else if (getLanguage() === 'it') {
            return this.json.urlIt;
        } else {
            return this.json.urlDe;
        }
    }

    get text() {
        if (getLanguage() === 'fr') {
            return this.json.textFr;
        } else if (getLanguage() === 'it') {
            return this.json.textIt;
        } else {
            return this.json.textDe;
        }
    }
}